.footerUl {
  list-style-type: none;
  list-style-position: inside;
  margin: 0px;
  padding: 0px;
}
.footerClass {
  font-size: 0.8rem;
  background-color: rgb(0, 0, 0);
  color: rgb(192, 192, 192);
}

.footerTitle {
  color: white;
}

a {
  color: rgb(136, 130, 130);
}
a:hover {
  color: rgb(238, 226, 226);
}

.subscribeEmail {
  border: 0px;
  background: transparent;
  appearance: none;
}
